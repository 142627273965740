<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title=" معلومات الفرد الشخصية " v-if="person.first_name">
          <title></title>
          <b-row>
        
            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> الاسم</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.first_name }}
                </h5>
              
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> الاب</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.father_name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> الام</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.mother_name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> النسبة</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.last_name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" v-if="person.gender">
              <b-form-group>
                <label> الجنس</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.gender.name }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="person.nationality">
              <b-form-group label-for="nationality">
                <label>الجنسية</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.nationality }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="person.current_work">
              <b-form-group label-for="current_work">
                <label> العمل الحالي</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.current_work }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="person.last_work">
              <b-form-group label-for="last_work">
                <label> العمل السابق</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.last_work }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="person.martial_status">
              <b-form-group label-for="martial_status">
                <label> الحالة الاجتماعية</label>

                <h5 class="text-capitalize mb-75">
                  {{ person.martial_status.name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" >
              <b-form-group label-for="birth_place">
                <label> مكان الولادة</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.birth_place }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3">
              <b-form-group label-for="note">
                <label> الملاحظات </label>

                <h5 class="text-capitalize mb-75">
                  {{ person.note }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="person.educational_attainment">
              <b-form-group label-for="educational_attainment">
                <label> التحصيل العلمي </label>

                <h5 class="text-capitalize mb-75">
                  {{ person.educational_attainmen}}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="person.educational_status">
              <b-form-group label-for="number">
                <label> حالة التعليم </label>
                <h5 class="text-capitalize mb-75">
                  {{ person.educational_status.name }}
                </h5>
              </b-form-group>
            </b-col>
          </b-row>
          <br />

          <b-row>
            <b-col xl="2"> <label> نقاط الضعف</label></b-col>
            <b-col v-for="(weak, index) in person.weaknesses" :key="index" xl="3">
              <b-form-group label-for="time">
                <h5 class="text-capitalize mb-75">
                  {{ weak.name }}
                </h5></b-form-group
              >
            </b-col>
          </b-row>
          <br />

          <b-row>
            <b-col md="6" xl="4">
              <b-form-group label-for="birth_date">
                <label> تاريخ الميلاد</label>
                <b-form-datepicker
                  id="example-datepicker5"
                  v-model="person.birth_date"
                  class="mb-1"
                  readonly
                />
                <!-- <b-form-input v-model="person.dateOfBirth" placeholder="" /> -->
              </b-form-group>
            </b-col>
          </b-row>

          
        </b-card-code>
     <b-card-code title=" ">
          <div v-if="person.contact_details">
            <b-row v-for="(phone, i) in person.contact_details" :key="i" >
              <b-col md="2" xl="3">
                <b-form-group label-for="call">
                  <label> وسبلة الاتصال</label>
                  <h5 class="text-capitalize mb-75">
                    {{ phone.contact_type.name }}
                  </h5>
                </b-form-group>
              </b-col>
              <b-col md="3" xl="2">
                <b-form-group label-for="cardNumber">
                  <label> الرقم</label>
                  <h5 class="text-capitalize mb-75">
                    {{ phone.number }}
                  </h5>
                </b-form-group>
              </b-col>
              <b-col md="12" xl="2">
                <b-form-group label-for="delimiter">
                  <label>الملاحظات</label>
                  <h5 class="text-capitalize mb-75">
                    {{ phone.notes }}
                  </h5>
                </b-form-group>
              </b-col>

              <br />
              <br />
            </b-row>
          </div>
          <div v-else>
  <b-col md="2" xl="3" v-if="person.contact_type">
                <b-form-group label-for="call">
                  <label> وسبلة الاتصال</label>
                  <h5 class="text-capitalize mb-75">
                    {{ person.contact_type.name }}
                  </h5>
                </b-form-group>
              </b-col>
              <b-col md="3" xl="2">
                <b-form-group label-for="cardNumber">
                  <label> الرقم</label>
                  <h5 class="text-capitalize mb-75">
                    {{ person.number }}
                  </h5>
                </b-form-group>
              </b-col>
              <b-col md="12" xl="2">
                <b-form-group label-for="delimiter">
                  <label>الملاحظات</label>
                  <h5 class="text-capitalize mb-75">
                    {{ person.notes }}
                  </h5>
                </b-form-group>
              </b-col>
          </div>
        </b-card-code>
         <b-card-code title=" ">
          <div v-if="person.documents">
            <b-row v-for="(document, index) in person.documents" :key="index">
              <b-col md="6" xl="4">
                <b-form-group label-for="cardNumber">
                  <label> نوع الوثيقة</label>
                  <h5 class="text-capitalize mb-75">
                    {{ document.document_type.name }}
                  </h5>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group label-for="number">
                  <label> رقم الوثيقة</label>
                  <h5 class="text-capitalize mb-75">
                    {{ document.number }}
                  </h5>
                </b-form-group>
              </b-col>

              <b-col md="6" xl="4">
                <!-- <validation-provider #default="{ errors }" name="daisp" :rules="rules"> -->
                <b-form-group label-for="date">
                  <label> تاريخ الأصدار</label>
                  <flat-pickr
                    v-model="document.release_date"
                    class="form-control"
                    readonly
                  />
                </b-form-group>
                <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider> -->
              </b-col>
              <b-col md="6" xl="4">
                <!-- <validation-provider #default="{ errors }" name="dateDisp" rules="required"> -->
                <b-form-group label-for="date">
                  <label> تاريخ الأنتهاء</label>
                  <flat-pickr
                    v-model="document.expiration_date"
                    class="form-control"
                    readonly
                  />
                </b-form-group>
                <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider> -->
              </b-col>
              <b-col md="12" xl="4">
                <b-form-group label-for="delimiter">
                  <label>الملاحظات</label>
                  <h5 class="text-capitalize mb-75">
                    {{ document.notes }}
                  </h5>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div v-if="person.document_type">
              <b-col md="6" xl="4" v-if="person.document_type">
                <b-form-group label-for="cardNumber">
                  <label> نوع الوثيقة</label>
                  <h5 class="text-capitalize mb-75">
                    {{ person.document_type.name }}
                  </h5>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group label-for="number">
                  <label> رقم الوثيقة</label>
                  <h5 class="text-capitalize mb-75">
                    {{ person.number }}
                  </h5>
                </b-form-group>
              </b-col>

              <b-col md="6" xl="4">
                <!-- <validation-provider #default="{ errors }" name="daisp" :rules="rules"> -->
                <b-form-group label-for="date">
                  <label> تاريخ الأصدار</label>
                  <flat-pickr
                    v-model="person.release_date"
                    class="form-control"
                    readonly
                  />
                </b-form-group>
                <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider> -->
              </b-col>
              <b-col md="6" xl="4">
                <!-- <validation-provider #default="{ errors }" name="dateDisp" rules="required"> -->
                <b-form-group label-for="date">
                  <label> تاريخ الأنتهاء</label>
                  <flat-pickr
                    v-model="person.expiration_date"
                    class="form-control"
                    readonly
                  />
                </b-form-group>
                <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider> -->
              </b-col>
              <b-col md="12" xl="4">
                <b-form-group label-for="delimiter">
                  <label>الملاحظات</label>
                  <h5 class="text-capitalize mb-75">
                    {{ person.notes }}
                  </h5>
                </b-form-group>
              </b-col>
          </div>
          
        </b-card-code>
        <b-card-code>
          <div v-if="person.proposal_services">
        
               <b-row v-for="(serv, i) in person.proposal_services" :key="i" >
              <b-col md="6" xl="4">
                <b-form-group label-for="servname">
                  <label> الخدمة</label>
                  <h5 class="text-capitalize mb-75">
                    {{ serv.proposal_service.name }}
                  </h5>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group label-for="sernmote">
                  <label> الملاحظة</label>
                  <h5 class="text-capitalize mb-75">
                    {{serv.notes }}
                  </h5>
                </b-form-group>
              </b-col>
              </b-row>

             
          </div>
        </b-card-code>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup } from "bootstrap-vue";

import "cleave.js/dist/addons/cleave-phone.us";

import {
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BForm,
  BButton,
  BImg,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
// import '@core/scss/vue/libs/vue-flatpicker.scss';
import "flatpickr/dist/flatpickr.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {
    BForm,
    BCardCode,

    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,

    BImg,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,

    BFormTextarea,

    BRow,

    BCol,
  },

  data(){
    return{
    person:{}
    }
  },
  created() {
    this.get();
   
  },

  methods: {
    get() {
     
       let url = `/api/v1/information_manager/pending_create_actions/${this.$route.params.id}`;
      //console.log(this.$route.params.id);
      this.$http.get(url).then((res) => {
        //console.log("hi",res);
        this.person = res.data.data;
          //console.log("hi",this.person);
       
      });
      // //console.log(this.personWeak);
    },
  
 
   
  }
};
</script>

<style scoped>
label {
  color: #7e7a9f;
  font-weight: bold;
  font-size: 12px;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #0971b8 !important;
}
.align-left {
  left: 50px;
}

.but {
  top: 40px;
}
</style>